import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ACTIVITIES } from '../../components/constant'
import IMGOutreach from '../../images/new/traning2.jpg'
const OutreachProgram = () => {
  return (
    <div>
      <Layout>
        <SEO title="Outreach Program" />
        <PageStructure
          title="Outreach Program"
          desc="TESTIG"
          mode={ACTIVITIES}
          image={IMGOutreach}
        />
      </Layout>
    </div>
  )
}

export default OutreachProgram
